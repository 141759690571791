div.style-tab-modifier {
  width: 100%;
  height: 100%;
  background: #1E1E1E;
}

div.style-tab-modifier .ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 0;
  border-top: 0;
}

div.style-tab-modifier .ant-tabs-content {
  width: 100%;
  height: calc(100% - 37px);
}

div.style-tab-modifier .ant-tabs-tab:not(.ant-tabs-tab-active) {
  color: rgba(255, 255, 255, 0.65);
}
