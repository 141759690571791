div.rendering-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

div.rendering-wrapper p {
  margin: 4px 0;
}

div.rendering-wrapper div.rendering-area {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 37px;
  box-sizing: border-box;
}

div.rendering-wrapper div.message-wrapper {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 37px;
  padding: 5px 10px;
  background: #1E1E1E;
  color: rgba(255, 255, 255, 0.65);
  box-sizing: border-box;
}

div.rendering-wrapper div.message-wrapper:hover {
  height: auto;
}

div.rendering-wrapper div.message-wrapper div.message-container h1 {
  margin: 0 0 5px;
  font-size: 18px;
}

div.rendering-wrapper div.message-wrapper div.message-container p.error {
  color: #f48fb1;
}

div.rendering-wrapper div.message-wrapper div.message-container h1 small {
  font-size: 12px;
  font-weight: normal;
}

div.rendering-wrapper p.picasso-version {
  position: absolute;
  right: 15px;
  box-sizing: border-box;
}
